@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  /* Old linear
  background: linear-gradient(to bottom right,#48bf91,#48bfa7,#021a83) fixed;
  */

  width: 100%;
  height: 100%;
  background-image: url("assets/web-bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment:fixed;
  margin: 0;
  padding-top: 5%;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

h1 svg {
  height: 1em;
  width: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}

summary {
  cursor: pointer;
}



.textBox{

  border: 3px solid black;
  border-radius: 1.125rem;
  padding: 3%;
  margin-left: 3rem;
  margin-right: 3rem;
  white-space: pre-wrap;
  height: max-content;
  width: auto;
  background-color: white;
  
  
}

.textInputBox {

  border: 2px solid black;
  
  margin-top: 2rem;
  width: 260px;
  

}

.logo-icon-black {
  margin-top: 1rem;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("assets/destiny-black.svg");
}

.logo-icon-white {
  margin-top: 1rem;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("assets/destiny-white.svg");
}

.logo-icon-navbar {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("assets/destiny-black.svg");
}


.blue-button{
  margin-top: 1rem;
  width: max-content;
  border-left: 10px solid #3c82f6;
  border-right: 10px solid #3c82f6;
}

.blue-button:hover{
  border-left: 10px solid #1c4ed8;
  border-right: 10px solid #1c4ed8;
}

.title{
  padding-bottom: 5%;
  color:white;
  
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}